.mainContainer {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(221, 224, 235);
  margin-top: -45px;
}

.mainContainer .title {
  color: white;
  padding-top: 45px;
  font-weight: 900;
  font-size: 4em;
}

.mainContainer .subtitle {
  color: white;
  font-weight: 700;
  margin-top: -50px;
  font-size: 3em;
}

.mainContainer .emojiLine {
  margin-top: -30px;
}

.mainContainer #screenshot {
  height: 50%;
}

.mainContainer #preorder {
  padding-top: 50px;
  padding-bottom: 30px;
}

.mainContainer #createdBy {
  color: black;
  font-size: 10px;
  font-weight: 300;
}

.mainContainer .link {
  color: black;
  text-decoration: none;
  font-size: 10px;
  font-weight: 500;
}

.mainContainer #coffee {
  margin-top: -10px;
}